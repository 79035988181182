import "./App.css";
import Home from "./pages/home";

import PrivateRoute from "./components/privateRoute";
import { AuthProvider } from "./context/AuthContext";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./context/ScrollToTop";
//pages
import Dashboard from "./pages/dashboard";
import SignUp from "./pages/signup";
import LogIn from "./pages/login";
import Forgot from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <ScrollToTop>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/dashboard" element={<PrivateRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
              </Route>

              <Route exact path="/signup" element={<SignUp />} />
              <Route exact path="/login" element={<LogIn />} />
              <Route exact path="/forgotPassword" element={<Forgot />} />
              <Route exact path="/resetPassword" element={<ResetPassword />} />
            </Routes>
          </ScrollToTop>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
