import React from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TaleIcon } from "../../../src/decktide.svg";

export default function LoginComponent() {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-gray-100 py-4 px-8 shadow-md">
        <h1 className="text-xl font-bold">Flagship AS</h1>
      </header>
      <nav className="bg-gray-200 py-4 px-8">
        {/* Navigation links can be added here */}
      </nav>
      <main className="flex-1 p-8">
        <p className="mb-4">
          Flagship is a privately held investment management firm headquartered
          in Oslo, Norway. The firm is invested in long-life, high-quality
          assets and businesses seeking exceptional risk-adjusted returns.
        </p>
        <p className="mb-2">Org. nr.: 824 959 382</p>
        <p className="mb-2">Prinsens gate 12,</p>
        <p className="mb-8">0152 Oslo</p>
        <p className="mb-2">
          Email:{" "}
          <a href="mailto:contact@flagshipmgmt.com" className="text-blue-500">
            contact@flagshipmgmt.com
          </a>
        </p>
      </main>
      <footer className="bg-gray-100 py-4 px-8 shadow-md">
        {/* Footer content goes here */}
      </footer>
    </div>
  );
}
