import { LockClosedIcon } from "@heroicons/react/solid";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as TaleIcon } from "../../../src/decktide.svg";
export default function SignUpComponent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    signup(email, password).then((userCredential) => {
      // Signed in
      navigate("/dashboard");
      const user = userCredential.user;
    });
  };

  return (
    <>
      <div className="mx-auto max-w-4xl">
        <div className="rounded min-h-full grid grid-cols-1 md:grid-cols-2 items-center justify-center py-12 px-4">
          <div
            className="rounded-l-lg hidden bg-center md:mr-16 
 md:block h-full bg-[url('https://images.unsplash.com/photo-1616587895596-11641131b78b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')]"
          ></div>
          <div className="mr-8 md:my-6 w-full">
            <div>
              <Link to="/">
                <TaleIcon></TaleIcon>
              </Link>
              <h2 className="mt-8 text-left text-3xl font-extrabold text-gray-900">
                La oss sette i gang.
              </h2>
              <p className="mt-2 text-base text-gray-600">
                Å registrere seg på Tale er raskt og gratis.
              </p>
            </div>
            <div className="flex justify-start mt-8">
              <div className="w-auto">
                <Link to="#"></Link>
              </div>
            </div>
            <div className="my-4">
              <p className="text-left text-base text-gray-600">eller</p>
            </div>
            <form
              onSubmit={handleSubmit}
              className="space-y-6"
              action="#"
              method="POST"
              autoComplete="off"
            >
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    E-postadresse
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="off"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="E-postadresse"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Passord
                  </label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Passord"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Repeter passord
                  </label>
                  <input
                    id="Rpassword"
                    name="Rpassword"
                    type="password"
                    autoComplete="off"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Skriv inn passordet på nytt"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                      aria-hidden="true"
                    />
                  </span>
                  Registrer
                </button>
                <p className="mt-2 text-sm text-gray-600">
                  Ved å registrere meg på Isak samtykker jeg til at jeg har lest
                  og godtatt{" "}
                  <Link
                    to="/login"
                    className=" text-blue-600 hover:text-blue-400"
                  >
                    personvernserklæringen
                  </Link>{" "}
                  og{" "}
                  <Link
                    to="/login"
                    className="text-sm  text-blue-600 hover:text-blue-400"
                  >
                    bruksvilkårene
                  </Link>
                  .
                </p>
                <div className="text-sm">
                  <p className="mt-8 text-left text-sm text-gray-600">
                    Har du allerede konto?{" "}
                    <Link
                      to="/login"
                      className="font-medium text-blue-600 hover:text-blue-400"
                    >
                      Logg inn
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
