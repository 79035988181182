"use client";
import * as React from "react";
import { PlaceholdersAndVanishInput } from "./placeholders";

export default function PlaceholdersAndVanishInputDemo() {
  const placeholders = [
    "What's our user retention rate?",
    "Where are users dropping off in our funnel?",
    "What are the most common user actions?",
    "How often are users sharing?",
    "Which sources have top conversion rates?",
    "How many items did users view before purchase?",
    "How do usage patterns vary among segments?",
  ];

  const handleChange = (e) => {
    console.log(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("submitted");
  };
  return (
    <div className="h-[40rem] flex flex-col justify-center  items-center px-4">
      <h2 className="mb-10 sm:mb-20 text-3xl text-center sm:text-5xl dark:text-white text-black ">
        Ask Decktide anything
      </h2>
      <PlaceholdersAndVanishInput
        placeholders={placeholders}
        onChange={handleChange}
        onSubmit={onSubmit}
      />
    </div>
  );
}
