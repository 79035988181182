import Header from "../components/header";
import HeaderPromoSection from "../components/HeaderpromoSection";
import PromoSection from "../components/Promosection";
import Footer from "../components/Footer";
import Testimonials from "../components/testimonials";

export default function Home() {
  return (
    <div>
      <HeaderPromoSection></HeaderPromoSection>
    </div>
  );
}
