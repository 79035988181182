import SignUpComponent from '../components/Login/signupComponent';

function SignUp()  {
    return (
    <SignUpComponent></SignUpComponent>)
    ;

  }
  
  export default SignUp;
