import { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  FaceSmileIcon,
  XMarkIcon,
  PencilSquareIcon,
  UsersIcon,
  CircleStackIcon,
  LinkIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import ReactDOM from "react-dom";
import ModalVideo from "react-modal-video";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import logo from "../decktide.svg";
import logoSquare from "../decktideSquare.svg";

import HeroSection1 from "../heroImage1.png";
import HeroSection2 from "../heroImage2.png";
import HeroSection3 from "../heroImage3.png";

import PlaceholdersAndVanishInputDemo from "./PlaceholderAndVanish";
import { FollowingPointerDemo } from "./FollowingPointerDemo";

const Securityfeatures = [
  {
    name: "AES-256 bit encryption",
    description:
      "Secured with the same encryption that banks use and ensure that no unauthorized parties can view sensitive information.",
  },
  {
    name: "Encrypted using TLS/SSL",
    description:
      "Your data is encrypted during transmission to protect it from unauthorized access.",
  },
  {
    name: "GDPR compliant",
    description:
      "We strive to comply with the General Data Protection Regulation to ensure the privacy and security of your data.",
  },
  {
    name: "IP Whitelisting and private VPC enforced",
    description:
      "We restrict access to your data by only allowing authorized IP addresses and using a private virtual private cloud.",
  },
  {
    name: "Regular penetration tests",
    description:
      "We regularly conduct security tests to identify and address any vulnerabilities in our systems.",
  },
  {
    name: "Single sign-on (SSO)",
    description:
      "Our single sign-on feature allows login with a single set of credentials, reducing the risk of password-related breaches",
  },
  {
    name: "Multi-factor authentication (MFA)",
    description:
      "We require multiple forms of authentication to verify your identity and protect your account from unauthorized access.",
  },
  {
    name: "Role-based access control",
    description:
      "Our role-based access control system ensures that users only have access to the information and features they need to perform their job functions.",
  },
];

const featuresDesc = [
  {
    name: "200+ integrations.",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
  },
  {
    name: "SSL certificates.",
    description:
      "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.",
  },
  {
    name: "Database backups.",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
  },
];

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Integrations", href: "#" },
  { name: "Company", href: "#" },
];
const features = [
  {
    name: "Get started in minutes",
    description: "A code snippet is all you need.",
    icon: CheckCircleIcon,
  },
  {
    //Everything you need is tracked.
    name: "Auto-collected data",
    description: "Every user action is tracked.",
    icon: ClockIcon,
  },
  {
    name: "Understand data instantly",
    description: "Analyze user behavior in plain English.",
    icon: ChartBarIcon,
  },

  {
    name: "Built to scale",
    description: "Designed to help your product grow.",
    icon: LinkIcon,
  },
];
const tiers = [
  {
    name: "Starter",
    id: "tier-freelancer",
    href: "https://mailchi.mp/5d2422c3e309/tale",
    priceMonthly: "Free",
    description: "The essentials to test it out.",
    month: "",
    features: [
      "1 board",
      "Up to 5 requests per month",
      "Support for CSV, JSON",
      "No collaboration",
    ],
    mostPopular: false,
  },
  {
    name: "Professional",
    id: "tier-startup",
    href: "https://mailchi.mp/5d2422c3e309/tale",
    priceMonthly: "$349",
    month: "/month",
    description: "A plan that scales with your team. Pay per seat.",
    features: [
      "Up to 25 boards",
      "Up to 1000 requests per month",
      "Direct data integrations",
      "Collaborate with your team",
      "Automations and live feed data",
    ],
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "https://mailchi.mp/5d2422c3e309/tale",
    priceMonthly: "Talk to sales",
    month: "",
    description: "Dedicated support and infrastructure for your company.",
    features: [
      "Unlimited boards",
      "Unlimited requests",
      "Advanced analytics",
      "1-hour, dedicated support response time",
    ],
    mostPopular: false,
  },
];
const faqs = [
  {
    id: 1,
    question: "What is Decktide?",
    answer:
      "Decktide is a platform that collects all the data product teams need. It helps you find important insights that make decision-making easier.",
  },
  {
    id: 2,
    question: "How can it help me?",
    answer:
      "It is great for ad-hoc questions where you need an answer quickly. With it, you can replace manually querying databases, or waiting for engineering to extract the data for you.",
  },
  {
    id: 3,
    question: "How technical do I have to be?",
    answer:
      "We make sure everyone can easily use our platform. Our goal is to empower any team member to be data-driven, regardless of technical skills. ",
  },

  // More questions...Product Analysis Collaborative Whiteboard Data Integration Real-time Collaboration Team Solutions
];
const footerNavigation = {
  solutions: [
    { name: "Product Analysis", href: "#" },
    { name: "Real-time Collaboration", href: "#" },
    { name: "Data Integration", href: "#" },
    { name: "Team Solutions", href: "#" },
  ],
  support: [
    { name: "Pricing", href: "#" },
    { name: "Documentation", href: "#" },
    { name: "Guides", href: "#" },
    { name: "Demos", href: "#" },
  ],
  company: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Careers", href: "#" },
    { name: "Press", href: "#" },
    { name: "Partners", href: "#" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
};

const Modal = ({ onClose }) => {
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto flex justify-center items-center bg-black bg-opacity-75">
      <div className="max-w-3xl bg-white rounded-lg p-6 overflow-hidden">
        <div className="relative" style={{ paddingBottom: "56.25%" }}>
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src="https://youtu.be/7GNJvafyxyM"
            title="Demo Video"
            allowFullScreen
          ></iframe>
        </div>
        <button
          className="absolute top-0 right-0 m-3 text-xl text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          &#x2715;
        </button>
      </div>
    </div>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const openVideo = () => {
    setIsVideoOpen(true);
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white">
      {/* Header */}
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8 max-w-6xl mx-auto"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Decktide</span>
              {/* Use the imported SVG */}
              <img
                className="h-6 w-auto"
                src={logo} // Use the imported SVG file
                alt="Your Company Logo"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-medium leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/"
              className="text-sm font-medium leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Decktide</span>
                <img className="h-4 w-auto" src={logoSquare} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <main className="isolate">
        {/* Hero section */}
        <div className="relative pt-14">
          {/* Hero section
          
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[rgb(156,163,175)] to-[rgb(17,24,39)] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          
          */}

          <div className="pt-24 sm:pt-24">
            <div className="mx-auto max-w-6xl px-6 lg:px-8">
              <div className="mx-auto max-w-3xl text-center">
                <h1 className="text-5xl tracking-tight text-gray-900 sm:text-6xl">
                  Data to build what your users want
                </h1>

                <p className="mt-6 text-lg leading-8 text-gray-600 mx-auto max-w-lg">
                  AI-driven product analytics in a few lines of code.
                </p>
                <p className=" text-lg leading-8 text-gray-600"></p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-zinc-950 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
                  >
                    Join the waitlist
                  </a>

                  <a
                    href="https://youtu.be/HAEgEVVICEw"
                    className="text-sm font-medium leading-6 text-gray-900 hover:text-zinc-500"
                    onClick={openModal}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Play demo video <span aria-hidden="true">▷</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-24 pb-24 sm:pt-0 sm:pb-24">
            <div className="mx-auto max-w-6xl px-6 lg:px-8">
              <FollowingPointerDemo></FollowingPointerDemo>
            </div>
          </div>

          {/* blur section
          
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[rgb(156,163,175)] to-[rgb(17,24,39)] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          */}
        </div>

        {/* Logo cloud 
        <div className="mx-auto max-w-6xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="mt-16 flex justify-center">
            <p className="relative rounded-full px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
              <span className="hidden md:inline">
                Transistor saves up to $40,000 per year, per employee by working
                with us.
              </span>
              <a href="#" className="font-medium text-zinc-600">
                <span className="absolute inset-0" aria-hidden="true" /> Read
                our case study <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>*/}

        {/* Testimonial section  saves founders precious time and resources
        <div className="mx-auto mt-32 max-w-6xl sm:px-6 lg:px-8">
          <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-2xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
            <img
              className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
              alt=""
            />
            <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
            <div
              className="absolute -left-80 -top-56 transform-gpu blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[rgb(156,163,175)] to-[rgb(17,24,39)] opacity-[0.45]"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div
              className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[rgb(156,163,175)] to-[rgb(17,24,39)] opacity-25"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div className="relative mx-auto max-w-2xl lg:mx-0">
              <img
                className="h-12 w-auto"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg"
                alt=""
              />
              <figure>
                <blockquote className="mt-6 text-lg font-medium text-white sm:text-xl sm:leading-8">
                  <p>“This is very cool guys.”</p>
                </blockquote>
                <figcaption className="mt-6 text-base text-white">
                  <div className="font-medium">Some guy</div>
                  <div className="mt-1">Product Manager, Workplace</div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
*/}
        {/* Feature section */}
        <div className="mx-auto mt-32 max-w-6xl px-6 sm:mt-56 lg:px-8">
          <div className="sm:mx-auto max-w-lg text-left sm:text-center">
            <p className="mt-2 text-4xl  tracking-tight text-gray-900 sm:text-5xl">
              Product analytics on autopilot
            </p>
            <p className="mt-6 text-lg sm:mx-auto max-w-sm leading-8 text-gray-600">
              Decktide tracks how all your users engage. Gain any insight from
              it in seconds.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-6xl">
            <dl className="lg:ml-[10%] grid max-w-xl grid-cols-1 gap-x-8 gap-y-20 sm:max-w-none sm:grid-cols-2 sm:gap-y-16 sm:gap-x-20">
              {features.map((feature) => (
                <div key={feature.name} className="relative ">
                  <dt className="text-base font-medium leading-7 text-gray-900">
                    {/* Features 
<div className="absolute left-0 top-0 flex h-10 w-10 lg:h-14 lg:w-14 items-center justify-center rounded-lg bg-zinc-950">
                      <feature.icon
                        className="h-6 w-6  text-white"
                        aria-hidden="true"
                      />
                    </div>*/}
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* Features */}

        <div className="overflow-hidden bg-white py-24 sm: pt-52 pb-32">
          <div className="mx-auto max-w-6xl md:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-x-8 lg:grid-cols-2 lg:items-start">
              <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                  <p className="mt-8 text-3xl   tracking-tight text-gray-900 sm:text-4xl">
                    Get started in minutes
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Replace months of engineering time with a code snippet.
                    Install once, done.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {featuresDesc.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-medium text-gray-900">
                          {featuresDesc.name}
                        </dt>{" "}
                        <dd className="inline">{featuresDesc.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="sm:px-6 lg:px-0">
                <div className="relative isolate overflow-hidden bg-zinc-950 max-h-96 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-lg sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                  <div
                    className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-zinc-100 opacity-20 ring-1 ring-inset ring-white"
                    aria-hidden="true"
                  />
                  <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                    <img
                      src={HeroSection1}
                      alt="Product screenshot"
                      width={2432}
                      height={1442}
                      className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10 "
                    />
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-lg"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-6xl md:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-x-8  lg:grid-cols-2 lg:items-start">
              <div className="lg:px-0 lg:pl-4 lg:pt-4 order-1 lg:order-2">
                {" "}
                {/* Title first on mobile */}
                <div className="px-6 lg:px-0 lg:pl-4 lg:pt-4 order-1 lg:order-2">
                  {" "}
                  {/* Title first on mobile */}
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                    <p className="mt-8 text-3xl tracking-tight text-gray-900 sm:text-4xl">
                      Access a wealth of insight
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      We automatically track every event across your whole app,
                      revealing exactly how users engage.
                    </p>
                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                      {featuresDesc.map((feature) => (
                        <div key={feature.name} className="relative pl-9">
                          <dt className="inline font-medium text-gray-900">
                            {featuresDesc.name}
                          </dt>{" "}
                          <dd className="inline">{featuresDesc.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
              <div className="sm:px-6 lg:px-0 lg:pr-4 lg:pt-4 order-2 lg:order-1">
                {" "}
                {/* Image first on larger screens */}
                <div className="relative isolate overflow-hidden bg-zinc-950 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-lg sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none max-h-96">
                  <div
                    className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-zinc-100 opacity-20 ring-1 ring-inset ring-white"
                    aria-hidden="true"
                  />
                  <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                    <img
                      src={HeroSection2}
                      alt="Product screenshot"
                      width={2432}
                      height={1442}
                      className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10 "
                    />
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-lg"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-6xl md:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-x-8  lg:grid-cols-2 lg:items-start">
              <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                  <p className="mt-8 text-3xl tracking-tight text-gray-900 sm:text-4xl">
                    Accelerate decision making
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Get answers to questions in seconds. Easily iterate with
                    follow-up requests and extract raw data when necessary.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {featuresDesc.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-medium text-gray-900">
                          {featuresDesc.name}
                        </dt>{" "}
                        <dd className="inline">{featuresDesc.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="sm:px-6 lg:px-0">
                <div className="relative isolate overflow-hidden bg-zinc-950 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-lg sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none max-h-96">
                  <div
                    className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-zinc-100 opacity-20 ring-1 ring-inset ring-white"
                    aria-hidden="true"
                  />
                  <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                    <img
                      src={HeroSection3}
                      alt="Product screenshot"
                      width={2432}
                      height={1442}
                      className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10 "
                    />
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-lg"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Ask questions section */}
        <PlaceholdersAndVanishInputDemo></PlaceholdersAndVanishInputDemo>

        {/* Security section */}

        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-6xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-16 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div>
                <h2 className="text-base font-medium leading-7 text-zinc-600">
                  Security
                </h2>
                <p className="mt-2 text-3xl tracking-tight text-gray-900 sm:text-4xl">
                  Best-in-class security practices
                </p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Decktide strives to meet the highest regulatory compliance and
                  security standards. For us, trust and security is not a bonus.
                  It is a licence to operate.
                </p>
              </div>
              <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                {Securityfeatures.map((Securityfeatures) => (
                  <div key={Securityfeatures.name} className="relative pl-9">
                    <dt className="font-semibold text-gray-900">
                      <CheckIcon
                        className="absolute left-0 top-1 h-5 w-5 text-zinc-500"
                        aria-hidden="true"
                      />
                      {Securityfeatures.name}
                    </dt>
                    <dd className="mt-2">{Securityfeatures.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        {/* Our job section */}
        <div className="mx-auto text-center mt-56 mb-56 max-w-6xl px-6 sm:mt-56 sm:mb-0 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-4xl  tracking-tight text-gray-900 sm:text-5xl">
              Spend your time building, not deciphering.
            </p>
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-zinc-950 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
              >
                Join the waitlist
              </a>
            </div>
          </div>
          <div className="mt-16 flow-root sm:my-52"></div>
        </div>

        {/* Pricing section 
        <div className="py-24 sm:pt-48">
          <div className="mx-auto max-w-6xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-medium leading-7 text-zinc-600">
                Pricing
              </h2>
              <p className="mt-2 text-4xl  tracking-tight text-gray-900 sm:text-5xl">
                Pricing plans for teams of&nbsp;all&nbsp;sizes
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              Get everyone on the same page - literally.
            </p>
            <p className="max-w-2xl mx-auto text-center text-lg leading-8 text-gray-600">
              Swift onboarding, immediate impact.
            </p>
            <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {tiers.map((tier, tierIdx) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                    tierIdx === 0 ? "lg:rounded-r-none" : "",
                    tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                    "flex flex-col justify-between rounded-2xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
                  )}
                >
                  <div>
                    <div className="flex items-center justify-between gap-x-4">
                      <h3
                        id={tier.id}
                        className={classNames(
                          tier.mostPopular ? "text-zinc-600" : "text-gray-900",
                          "text-lg font-medium leading-8"
                        )}
                      >
                        {tier.name}
                      </h3>
                      {tier.mostPopular ? (
                        <p className="rounded-full bg-zinc-600/10 px-2.5 py-1 text-xs font-medium leading-5 text-zinc-600">
                          Early Access
                        </p>
                      ) : null}
                    </div>
                    <p className="mt-4 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-4xl  tracking-tight text-gray-900">
                        {tier.priceMonthly}
                      </span>
                      <span className="text-sm font-medium leading-6 text-gray-600">
                        {tier.month}
                      </span>
                    </p>
                    <ul
                      role="list"
                      className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-zinc-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-zinc-950 text-white shadow-sm hover:bg-zinc-500"
                        : "text-zinc-950 ring-1 ring-inset ring-zinc-200 hover:ring-zinc-300",
                      "mt-8 block rounded-md py-2 px-3 text-center text-sm font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
                    )}
                  >
                    Continue
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>*/}

        {/* FAQs */}
        <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-6xl lg:px-8 lg:pb-32">
          <h2 className="text-2xl  leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
              >
                <dt className="text-base font-medium leading-7 text-gray-900 lg:col-span-5">
                  {faq.question}
                </dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">
                    {faq.answer}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* CTA section */}
        <div className="relative -z-10 mt-32 px-6 lg:px-8">
          {/* Blur section 
        
        <div
            className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[rgb(156,163,175)] to-[rgb(17,24,39)] opacity-25"
              style={{
                clipPath:
                  "polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)",
              }}
            />
          </div>
        
        
        */}

          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl  tracking-tight text-gray-900 sm:text-4xl">
              Your product insights are ready.
              <br />
              Are you?
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              Go from blank page to answers in seconds with Decktide.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-zinc-950 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
              >
                Join the waitlist
              </a>
              <a
                href="#"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>

          {/* CTA section 
        
  <div
            className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[rgb(156,163,175)] to-[rgb(17,24,39)] opacity-30"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        
        
        */}
        </div>
      </main>

      {/* Footer */}
      <div className="mx-auto mt-32 max-w-6xl px-6 lg:px-8">
        <footer
          aria-labelledby="footer-heading"
          className="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32"
        >
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            {/* Use the imported SVG */}
            <img
              className="h-8 w-auto"
              src={logo} // Use the imported SVG file
              alt="Decktide"
            />

            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-medium leading-6 text-gray-900">
                    Solutions
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {footerNavigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-medium leading-6 text-gray-900">
                    Support
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {footerNavigation.support.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-medium leading-6 text-gray-900">
                    Company
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {footerNavigation.company.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-medium leading-6 text-gray-900">
                    Legal
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {footerNavigation.legal.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
