import { useAuth } from "../context/AuthContext";
import { getAuth, signOut } from "firebase/auth";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import HeaderLoggedIn from "../components/headerLoggedIn";
import DashboardHeader from "../components/Dashboard/DashboardHeader";
import axios from "axios";
import { BellIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { RadioGroup } from "@headlessui/react";
import { Link } from "react-router-dom";

const product = {
  name: "Velkommen til Tale.",
  price: "Velg kommando og trykk send",
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Men", href: "#" },
    { id: 2, name: "Clothing", href: "#" },
  ],
  images: [
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg",
      alt: "Two each of gray, white, and neutral-900 shirts laying flat.",
    },
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg",
      alt: "Model wearing plain neutral-900 basic tee.",
    },
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg",
      alt: "Model wearing plain gray basic tee.",
    },
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg",
      alt: "Model wearing plain white basic tee.",
    },
  ],
  colors: [],
  sizes: [
    { name: "Generer", inStock: true },
    { name: "Oppsummer", inStock: true },
    { name: "Forhandle", inStock: true },
    { name: "Anbefal endringer", inStock: true },
  ],
  description: "Lim inn juridisk tekst nedenfor",
  highlights: [],
  details: "",
};
const reviews = { href: "#", average: 4, totalCount: 117 };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let OpenAI;

function Dashboard() {
  const auth = useAuth();
  const users = collection(db, "users");
  const { currentUser } = useAuth();
  const [verified, setVerified] = useState(false);

  const [message, setMessage] = useState("");
  const [outputMessage, setOutputMessage] = useState("");

  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [selectedSize, setSelectedSize] = useState(product.sizes[2]);
  useEffect(() => {
    async function checkIfUserExists() {
      const docRef = doc(db, "users", currentUser.uid);
      const userRef = collection(db, "users");
      const userDoc = await getDoc(docRef);
      console.log(userDoc);
      if (!userDoc.exists()) {
        await setDoc(doc(userRef, currentUser.uid), {
          userId: currentUser.uid,
          verified: false,
        });
      } else {
        setVerified(userDoc.data().verified);
      }
    }
    checkIfUserExists();

    /*async function getOpenAI() {
      let res = await axios.get(
        "https://us-central1-vhf-openai.cloudfunctions.net/hello"
      );
    
     OpenAI = res.data;
    console.log("inni kommando: " + OpenAI);
    }*/
  }, []);

  function handleClick() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  function hClick() {
    async function makeGetRequest() {
      setOutputMessage("Tale tenker...");
      let OpenAI = await axios.get(
        "https://us-central1-vhf-openai.cloudfunctions.net/hello"
      );
      let inputText = message;
      if (inputText) {
        let header = {
          headers: {
            Authorization: `Bearer ` + OpenAI["data"],
          },
        };

        let payload = {
          prompt: "\nYou: " + inputText + "\nAutomat Express: ",
          temperature: 0.2,
          max_tokens: 100,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0.6,
          stop: ["You:", " Automat Express:"],
        };

        let res = await axios.post(
          "https://api.openai.com/v1/engines/text-davinci-002/completions",
          payload,
          header
        );

        let OpenAIdata = res.data;
        let StringOpenAIdata = OpenAIdata.choices[0].text.replace("\n\n", "");
        setOutputMessage(StringOpenAIdata);
      } else console.log("Not allowed to send request without content");
    }
    makeGetRequest();
  }

  //const {currentUser} = useAuth()
  return (
    <div className="Dashboard text-gray-600">
      <HeaderLoggedIn></HeaderLoggedIn>
      <div className=" inline-flex rounded-md shadow">
        <button
          onClick={handleClick}
          className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50"
        >
          Logg ut
        </button>
      </div>

      <div className="bg-white">
        <div className="pt-6">
          {/* Product info */}
          <div className="mx-auto px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-10 lg:pb-24">
            <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {product.name}
              </h1>
              <h2 className="mt-2 first-letter:text-xl font-normal tracking-tight text-gray-500 sm:text-xl">
                {product.description}
              </h2>
              <div className="mt-1 flex rounded-md shadow-sm pt-5 text-sm font-medium text-gray-700">
                <textarea
                  name="Lawinput"
                  id="Lawinput"
                  maxLength="1500"
                  rows={3}
                  className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  placeholder="Lim inn juridisk tekst (maks 1500 bokstaver)"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              <h2 className="sr-only">Product information</h2>
              <p className=" font-normal tracking-tight text-gray-500 ">
                {product.price}
              </p>

              {/* Reviews */}

              <form>
                {/* Sizes */}
                <div className="mt-4">
                  <RadioGroup
                    value={selectedSize}
                    onChange={setSelectedSize}
                    className="mt-4"
                  >
                    <RadioGroup.Label className="sr-only">
                      {" "}
                      Choose a size{" "}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                      {product.sizes.map((size) => (
                        <RadioGroup.Option
                          key={size.name}
                          value={size}
                          disabled={!size.inStock}
                          className={({ active }) =>
                            classNames(
                              size.inStock
                                ? "bg-white shadow-sm text-gray-900 cursor-pointer"
                                : "bg-gray-50 text-gray-200 cursor-not-allowed",
                              active ? "ring-2 ring-blue-500" : "",
                              "group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <RadioGroup.Label as="span">
                                {size.name}
                              </RadioGroup.Label>
                              {size.inStock ? (
                                <span
                                  className={classNames(
                                    active ? "border" : "border-2",
                                    checked
                                      ? "border-blue-500"
                                      : "border-transparent",
                                    "pointer-events-none absolute -inset-px rounded-md"
                                  )}
                                  aria-hidden="true"
                                />
                              ) : (
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                >
                                  <svg
                                    className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                    stroke="currentColor"
                                  >
                                    <line
                                      x1={0}
                                      y1={100}
                                      x2={100}
                                      y2={0}
                                      vectorEffect="non-scaling-stroke"
                                    />
                                  </svg>
                                </span>
                              )}
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <button
                  type="button"
                  onClick={hClick}
                  className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 py-3 px-8 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Send
                </button>
                <div className="mt-2">
                  <div>
                    <button
                      type="submit"
                      className="group relative flex justify-start rounded-md border border-transparent  py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center">
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-blue-600 group-hover:text-blue-400"
                          aria-hidden="true"
                        />
                      </span>
                      <span className="ml-2 text-blue-600 group-hover:text-blue-400">
                        Hjelp
                      </span>
                    </button>
                  </div>
                </div>
              </form>
              <div className="">
                <label
                  htmlFor="about"
                  className="block pt-5 text-xl font-medium text-gray-900"
                >
                  Svar fra Tale:
                </label>
                <div className="mt-1">
                  <p className="mt-2 text-xl text-gray-500">{outputMessage}</p>
                </div>

                <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-10"></div>
              </div>
            </div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8"></div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default Dashboard;
