import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ForgotComponent() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { resetPassword } = useAuth();

  const [clicked, setClicked] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    resetPassword(email);
    setClicked(true);
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <div className="mx-auto ">
            <Link to="/">
            </Link>
          </div>

          {clicked ? (
            <div>
              <div className="mt-12">
                <p className="text-left text-base text-gray-600">
                  <h2 className="mt-4 text-left text-3xl font-semibold text-gray-900">
                    Sjekk e-posten din
                  </h2>
                  <p className="font-medium mt-4"></p>En lenke ble sendt til{" "}
                  <span className="font-medium text-gray-900">{email}</span> for
                  å tilbakestille passordet ditt. Hvis du ikke finner e-posten i
                  innboksen din, bør du sjekke søppelpost.
                </p>
              </div>
              <div className="text-center mt-6">
                <p className="">
                  <Link
                    to="/login"
                    className="font-medium text-sm text-blue-600 hover:text-blue-400"
                  >
                    Tilbake til logg inn
                  </Link>
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="mt-12">
                <p className="text-left text-base text-gray-600">
                  <h2 className="mt-4 text-left text-3xl font-semibold text-gray-900">
                    Tilbakestill passordet ditt
                  </h2>
                  <p className="font-medium mt-4"></p> Skriv inn e-postadressen
                  som er knyttet til kontoen din, så sender vi deg en lenke for
                  å tilbakestille passordet ditt.
                </p>
              </div>
              <form
                onSubmit={handleSubmit}
                className="mt-4 space-y-6"
                action="#"
                method="POST"
              >
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      E-postadresse
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                      placeholder="E-postadresse"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                    Send e-post
                  </button>
                </div>
                <div className="text-center">
                  <p className="">
                    <Link
                      to="/login"
                      className="font-medium text-sm text-blue-600 hover:text-blue-400"
                    >
                      Tilbake til logg inn
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
