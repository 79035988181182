import {Link} from 'react-router-dom'
import { useAuth } from "../../context/AuthContext"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ResetComp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault();
    login(email, password).then((userCredential) => {
      // Signed in 
      navigate('/dashboard')
      const user = userCredential.user;
  })
  }


  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <div className='mx-auto '>
          <Link to="/">
            logo
          </Link>
            
          </div>

          <div className='mt-12 text-left text-base text-gray-600' >

          <h2 className="mt-4 text-left text-3xl font-semibold text-gray-900">Tilbakestill passordet ditt</h2>
        
          </div>
          <form onSubmit={handleSubmit} className="mt-4 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">


                <div>
                <p className="font-medium mb-2">Nytt passord</p>
                <label htmlFor="password" className="sr-only">
                  Passord
                </label>
                <input
                  onChange={e => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none  relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Passord"
                />
              </div>
              <div>
              <p className="font-medium mt-4 mb-2">Bekreft nytt passord</p>
                <label htmlFor="password" className="sr-only">
                  Passord
                </label>
                <input
                  onChange={e => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 roundedfocus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Passord"
                />
              </div>
              
            </div>

            

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  
                </span>
                Fortsett
              </button>
            </div>

          </form>
        </div>
      </div>
    </>
  )
}