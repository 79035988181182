import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import {getFirestore} from 'firebase/firestore'

const app = firebase.initializeApp({
  apiKey: "AIzaSyDFXYv6AOhACU7EZWro0PDH09awACpzWP4",
  authDomain: "ares-test-bf129.firebaseapp.com",
  projectId: "ares-test-bf129",
  storageBucket: "ares-test-bf129.appspot.com",
  messagingSenderId: "343218317194",
  appId: "1:343218317194:web:167a42c0dc978cd53bca73",
  measurementId: "G-W6DVWGPTSM"
});

export const auth = firebase.auth(app);
export const db = getFirestore(app);
export default app;